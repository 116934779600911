import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import * as topojson from "topojson-client";
import { color, rgb } from "d3";

const useResizeObserver = (ref) => {
  const [dimensions, setDimensions] = useState(null);
  useEffect(() => {
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDimensions(entry.contentRect);
      });
    });
    resizeObserver.observe(observeTarget);
    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);

  return dimensions;
};

const senegalLocale = {
  decimal: ",",
  thousands: "\u00a0",
  grouping: [3],
  currency: ["", "\u00a0€"],
};

const formatMapper = {
  Population: ",",
  "Incidence Pauvreté": ".0%",
  "Taux Electrification": ".0%",
  "Taux Accès Eau Potable": ".0%",
  "Taux Accès Toilettes Améliorées": ".0%",
  "Nombre de Centre de Santé": ",",
  "Nombre de Poste de Santé": ",",
  "Nombre d'Hôpitaux": ",",
  "Incidence Diarrhée": ",",
  "Nombre d'Ecoles Maternelles": ",",
  "Taux Brut de Scolarisation Primaire": ",",
};

const seLocale = d3.formatLocale(senegalLocale);

// console.log("let see: ", seLocale.format(",.2f")(104342.42));

const capitalize = (str) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

const candidateMap = {
  "MACKY SALL": 0,
  "IDRISSA SECK": 1,
  "OUSMANE SONKO": 2,
  "MADICKE NIANG": 3,
  "EL HADJI SALL": 4,
};

const candidateProps = {
  "MACKY SALL": {
    primaryColor: rgb(91, 59, 21),
    secondaryColor: rgb(254, 247, 219),
    party: "APR",
  },
  "IDRISSA SECK": {
    primaryColor: rgb(211, 111, 46),
    secondaryColor: rgb(2, 2, 2),
    party: "PDS",
  },
  "OUSMANE SONKO": {
    secondaryColor: rgb(36, 91, 48),
    primaryColor: rgb(144, 25, 29),
    party: "PASTEF",
  },
  "MADICKE NIANG": {
    secondaryColor: "#feff73",
    primaryColor: "#004f92",
    party: "PDS",
  },
  "EL HADJI SALL": {
    primaryColor: "#339a51",
    secondaryColor: "#ffffff",
    party: "PUR",
  },
};

function SenelectMap({ data, config }) {
  const svgRef = useRef();
  // const tooltipRef = useRef();
  // const tooltipDiv = d3.select(".tooltip");
  const wrapperRef = useRef();
  const wrapDim = useResizeObserver(wrapperRef);
  const tooltip = d3.select(".tooltip");
  let tooltipHidden = true;
  console.log("coming in with config: ", config);

  useEffect(() => {
    if (!wrapDim) return;

    const svg = d3.select(svgRef.current);

    const width = wrapDim.width;
    const height = wrapDim.height < 400 ? 400 : wrapDim.height;

    const geoData = data.geography[1];

    svg.attr("width", "100%").attr("height", "100%");

    const zoom = d3.zoom().scaleExtent([1, 100]).on("zoom", zoomed);

    const colorScale = d3
      .scaleLinear()
      .domain([0, 1])
      .range(["white", "orange"]);

    let demographicData = [];
    let metricMax = 0;

    if (config.option == 1) {
      demographicData = data.demographics.map((d) => d[config.suboption]);
      metricMax = d3.max(demographicData);
    }

    const socDemColorScale = d3
      .scaleSequential(d3.interpolatePurples)
      .domain([0, metricMax]);

    // console.log("idem sa dem: ", data);

    const departments = topojson.feature(geoData, geoData.objects["sen2.geo"]);

    //enrich features data
    departments.features.forEach((d) => {
      const department = data.voting.find(
        (dep) => dep.department === d.properties.NAME_2
      );

      const socDemDepartment = data.demographics.find(
        (dep) => dep.Department === d.properties.NAME_2
      );

      if (!socDemDepartment)
        console.log("no soc dem data for: ", d.properties.NAME_2);

      if (department) {
        d.properties.voters = department.voters;
        d.properties.nulls = department.nulls;
        d.properties.valid = department.valid;
        d.properties.votes = department.votes;
        d.properties.metric = socDemDepartment[config.suboption];

        let winnerIndex = department.votes.indexOf(
          Math.max(...department.votes)
        );
        d.properties.winner = Object.keys(candidateMap)[winnerIndex];
        // d.properties.winnerParty = candidateProps[d.properties.winner].party;
        d.properties.winnerColor =
          candidateProps[d.properties.winner].primaryColor;
        d.properties.winnerSecondaryColor =
          candidateProps[d.properties.winner].secondaryColor;

        d.properties.winnerPercentage =
          department.votes[winnerIndex] / department.valid;
      } else {
        console.log("no department found for ", d.properties.NAME_2);
      }
    });

    const geoPath = d3.geoPath(
      d3.geoMercator().fitExtent(
        [
          [0, 40],
          [width * 0.95, height * 0.95],
        ],
        departments
      )
    );

    const mapGroup = svg.select("#map");

    mapGroup.selectAll("path").remove();

    const mapFeatures = mapGroup
      .selectAll("path")
      .data(departments.features)
      .enter()
      .append("path")
      .attr("class", "commune-path")
      .attr("stroke", "black")
      // .attr("stroke", "#f9efd8")
      .attr("fill", (d) => {
        if (config.option == 0) {
          colorScale.range(["white", d.properties.winnerColor]);
          return colorScale(d.properties.winnerPercentage);
        } else if (config.option == 1) {
          return socDemColorScale(d.properties.metric);
        } else return "black";
      })
      .attr("d", (d) => geoPath(d));

    if (config.option == 1) {
      mapFeatures
        .append("title")
        .text(
          (d) =>
            `Dept.: ${d.properties.NAME_2}\nRégion: ${d.properties.NAME_1}\n${
              config.suboption
            }:  ${seLocale.format(formatMapper[config.suboption])(
              d.properties.metric
            )}`
        );
    } else if (config.option == 0) {
      mapFeatures
        .on("mouseover", function (event, d) {
          d3.select(this).classed("highlighted", true).raise();
          tooltipHidden = !tooltipHidden;
          // console.log('overed! ', tooltipHidden)

          let top = event.y;
          if (top > height * 0.65) top -= 200;

          let left = event.x;
          if (left > width * 0.65) left -= 400;

          tooltip
            .style("top", `${top}px`)
            .style("left", `${left}px`)
            .style("visibility", tooltipHidden ? "hidden" : "visible");
          tooltip
            .select("#tooltip-department")
            .text(`Dept.: ${d.properties.NAME_2}`);
          tooltip
            .select("#total-voters")
            .text(`Inscrits: ${seLocale.format(",")(d.properties.voters)}`);
          tooltip
            .select("#tooltip-region")
            .text(`Région: ${d.properties.NAME_1}`);
          tooltip
            .select("#total-votes")
            .text(
              `Votes: ${seLocale.format(",")(
                d.properties.valid
              )} (${seLocale.format(".0%")(
                d.properties.valid / d.properties.voters
              )})`
            );

          const tableBody = tooltip.select("#tooltip-table-body");
          tableBody.selectAll("tr").remove();

          const sorted = [...d.properties.votes].sort((a, b) => b - a);

          // console.log(sortedMap);

          sorted.forEach((vote, i) => {
            const index = d.properties.votes.findIndex((d) => d === vote);

            const row = tableBody.append("tr");

            const candidateName = Object.keys(candidateMap)[index];

            // console.log(`im on index ${index} going to append ${vote} for ${candidateName}`)

            const candidateCell = row.append("td").attr("class", "color-box");
            candidateCell
              .append("span")
              .style(
                "background-color",
                candidateProps[candidateName].primaryColor
              );

            candidateCell.append("span").text(candidateName);
            row.append("td").text(candidateProps[candidateName].party);
            row.append("td").text(seLocale.format(",")(vote));
            row
              .append("td")
              .text(seLocale.format(".2f")((vote / d.properties.valid) * 100));
            if (i === 0) row.append("td").text("%");
          });
        })
        .on("mousemove", function (event, d) {
          let top = event.y + 50;
          if (top > height * 0.55) top -= 300;

          let left = event.x;
          if (left > width * 0.55) left -= 450;

          tooltip.style("top", `${top}px`).style("left", `${left}px`);
        })
        .on("mouseout", function (event, d) {
          d3.select(this).classed("highlighted", false);
          tooltipHidden = true;
          tooltip.style("visibility", tooltipHidden ? "hidden" : "visible");
        });
    }

    if (config.option === 0) {
      svg.select("#legend").selectAll("*").remove();

      const legendGroup = svg
        .select("#legend")
        .attr("transform", `translate(20,20)`);
      const candidateArray = Object.keys(candidateMap);
      candidateArray.forEach((candidate, index) => {
        const props = candidateProps[candidate];

        const legendEntry = legendGroup
          .append("g")
          .attr("transform", `translate(0, ${index * 32})`);

        const color = d3
          .scaleLinear()
          .domain([0, 1])
          .range(["white", props.primaryColor]);

        legendEntry
          .append("rect")
          .attr("width", 16)
          .attr("height", 16)
          .attr("stroke", "black")
          .attr("stroke", (d) => d3.lab(color(0.85)).darker(3))
          .attr("stroke-width", 0.5)
          .attr("fill", color(0.85));

        legendEntry
          .append("text")
          .attr("class", "legend-label")
          .attr("dx", 20)
          .attr("dy", 14)
          .text(
            candidate
              .split(" ")
              .map((d) => capitalize(d))
              .join(" ")
          );
      });
    } else if (config.option === 1) {
      svg
        .select("#legend")
        .selectAll("*")
        .transition()
        .duration(1000)
        .style("opacity", 0)
        .remove();
    }

    svg.call(zoom);

    function zoomed(event) {
      const { transform } = event;
      // tooltip.style("visibility", "hidden");
      mapGroup.attr("transform", transform);

      const header = d3.select(".row.header");
      const footer = d3.select(".row.footer");
      // const featured = d3.select(".content-featured");

      if (transform.k > 1) {
        // legendGroup.transition().style("opacity", 0);
        header.transition().style("opacity", 0);
        // featured.transition().style("opacity", 0);
        footer.transition().style("opacity", 0);

        svg
          .selectAll(".commune-path")
          .attr("stroke-width", 1 / (transform.k * 0.5));
      } else {
        // legendGroup.transition().style("opacity", 1);
        header.transition().style("opacity", 1);
        // featured.transition().style("opacity", 1);
        footer.transition().style("opacity", 1);
      }
    }
  }, [wrapDim, data, config]);

  const closeOverlay = () => {
    d3.select("#districtOverlay")
      .style("opacity", 0)
      .style("border", "none")
      .style("pointer-events", "none");
  };

  return (
    <div ref={wrapperRef} className="viz-wrapper">
      <svg ref={svgRef}>
        <g id="map" />
        <g id="legend" />
      </svg>
    </div>
  );
}

export default SenelectMap;
