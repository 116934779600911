import * as d3 from "d3";
import { useState, useEffect } from "react";

const capitalize = (str) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

const capitalizeAll = (str) => {
  return str
    .split(" ")
    .map((d) => capitalize(d))
    .join(" ");
};

export const useData = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const urls = [
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vSEkUTXVGoDju3DqkzuFTXpR23mMUrg8cGFR-1H17jjsHq8J6a4Pu_MC0DzxoVytYJ5-5XzfdEwSpU9/pub?output=csv&gid=259790553",
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vSEkUTXVGoDju3DqkzuFTXpR23mMUrg8cGFR-1H17jjsHq8J6a4Pu_MC0DzxoVytYJ5-5XzfdEwSpU9/pub?output=csv&gid=870634937",
  ];

  useEffect(() => {
    // console.log("useData.js; fetching ", new Date().getMilliseconds());
    const promises = [
      d3.json("data/sen1.topo.json"),
      d3.json("data/sen2.topo.json"),
      d3.json("data/sen3.topo.json"),
      d3.json("data/sen4.topo.json"),
      d3.csv(urls[0], (d) => {
        return {
          region: capitalizeAll(d.Region),
          department:
            d.Department === "SAINT-LOUIS"
              ? "Saint-Louis"
              : d.Department === "Nioro du Rip"
              ? "Nioro du Rip"
              : capitalizeAll(d.Department),
          voters: +d.Voters,
          nulls: +d.Nulls,
          valid: +d.Valid,
          votes: [
            +d["MACKY SALL"],
            +d["IDRISSA SECK"],
            +d["OUSMANE SONKO"],
            +d["MADICKE NIANG"],
            +d["EL HADJI SALL"],
          ],
        };
      }),
      d3.csv(urls[1], d3.autoType),
    ];

    Promise.all(promises).then((data) => {
      const socDem = data[5];

      console.log('ssssss', socDem);

      socDem.forEach((item) => {
        // console.log('going for: ', item)
        item.Department = item.Department === "SAINT-LOUIS"
        ? "Saint-Louis"
        : item.Department === "Nioro du Rip"
        ? "Nioro du Rip"
        : capitalizeAll(item.Department)
      });

      setData({
        geography: [data[0], data[1], data[2], data[3]],
        voting: data[4],
        demographics: socDem,
        electionData: null,
        socioeconomicData: null,
      });

      setLoading(false);
    });

    return () => undefined;
  }, []);

  return {
    data,
    loading,
  };
};
