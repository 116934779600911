import "./App.css";
import { useData } from "./useData";
// import * as d3 from "d3";
import SenelectMap from "./SenelectMap";
import { useState } from "react";

function App() {
  const { data, loading } = useData();
  const [config, changeConfig] = useState({
    option: 0,
    suboption: "Population",
  });

  const handleChange = (e) => {
    changeConfig((config) => ({ ...config, option: +e.target.value }));
  };

  const handleChangeCategory = (e) => {
    changeConfig((config) => ({ ...config, suboption: e.target.value }));
  };

  return (
    <div className="container">
      <div className="row header">
        <div className="mainTitle">
          Senegal: Données Socio-Démographiques & Politiques
        </div>
        <div className="chartSubtitle">
          <p>
            Sélectionnez les données{" "}
            <select
              className="title-parameter"
              name="optionList"
              onChange={handleChange}
            >
              <option value={0}>Présidentielles – 24 fév. 2019</option>
              <option value={1}>SocioDemographie</option>
              {/* <option value={2}>Locale – 23 janv. 2022</option>
              <option value={3}>Legislatives – 31 juil. 2022 (soon)</option> */}
            </select>
          </p>
          {!loading && config.option === 1 && (
            <p>
              catégorie:{" "}
              <select
                className="title-parameter"
                name="socDemOptionList"
                onChange={handleChangeCategory}
              >
                {data.demographics.columns.map((col, i) => {
                  if (i === 0) return;
                  return (
                    <option key={i} value={col}>
                      {col}
                    </option>
                  );
                })}
              </select>
            </p>
          )}
        </div>
      </div>
      <div className="row content">
        {loading && <div className="loading">Chargement des données, merci de patienter...</div>}
        {!loading && (
          <div className="content-map">
            <SenelectMap data={data} config={config} />
            {/* <h1>{data.geography.length}</h1> */}
            {/* {!loading && <CongressMap data={data} metric={config.metric} />} */}
          </div>
        )}
      </div>
      <div className="row footer">
        <div className="logoContainer" title="Open homepage in new tab">
          {/* <img src="enerball.svg" className="logoSvg"></img> */}
          <span className="logotext">SENELECT</span>
        </div>
      </div>
      <div className="tooltip">
        <div className="tooltip-title-row">
          <h4 id="tooltip-department"></h4>
          <span id="total-voters"></span>
        </div>
        <div className="tooltip-title-row">
          <h6 id="tooltip-region"></h6>
          <span id="total-votes"></span>
        </div>

        <table id="tooltip-table">
          <thead>
            <tr className="tooltip-table-header">
              <th className="left">Candidats</th>
              <th className="left">Parti</th>
              <th className="right">Total</th>
              <th className="right">Pct</th>
              <th className="right"></th>
            </tr>
          </thead>
          <tbody id="tooltip-table-body"></tbody>
        </table>

        {/* <div className="tooltip-content">

        </div> */}
      </div>
    </div>
  );
}

export default App;
